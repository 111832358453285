import "./Scss/styles.scss";
import "./Scss/modules.scss";

// import "./images/spearicon.jpg";

import "./pages/login.html";
import "./pages/sales.html";
import "./pages/admin_sales.html";
import "./pages/summary_sales.html";

import { helpers } from "./js/help";

document.addEventListener("DOMContentLoaded", async () => {
  var top_navs = document.querySelectorAll('#top_nav li');
  top_navs.forEach(function(item) {
     item.style.display='none';
  });
  var side_navs = document.querySelectorAll('#side_nav li');
  side_navs.forEach(function(item) {
     item.style.display='none';
  });
  helpers.prep({side_nav:'login'});
});

